import { IHistogramState, statePropName } from './reducer';
import { HistogramError } from './types';
import { calcComparison, calcGoal, getResultNormalizedPerformance } from './utils';

export interface IAppState {
  // this feature state
  [statePropName]: IHistogramState;
  // other features we don't care of
  [otherKeys: string]: any;
}

const getFeatureState = (state: IAppState) => state[statePropName];

export const isLoading = (state: IAppState) => getFeatureState(state).loading;

export const getUsersHistograms = (state: IAppState, userId?: number) => {
  if (!userId) {
    return getFeatureState(state).histogram;
  }
  return getFeatureState(state).users[userId] ?? {};
};

export const getWorkoutHistogram = (state: IAppState, workoutId: number, userId?: number) => {
  return getUsersHistograms(state, userId)[`${workoutId}`];
};

export const isWorkoutHistogramLoaded = (state: IAppState, workoutId: number, userId?: number) =>
  !!getWorkoutHistogram(state, workoutId, userId);

export const getWorkoutComparison = (state: IAppState, workoutId: number, score: number, userId?: number) => {
  const histogram = getWorkoutHistogram(state, workoutId, userId);
  if (!histogram) {
    return 0;
  }
  return calcComparison(score, histogram);
};

export const getWorkoutGoal = (state: IAppState, workoutId: number) => {
  const histogram = getWorkoutHistogram(state, workoutId);
  if (!histogram) {
    return 0;
  }
  return calcGoal(histogram);
};

export const getResultNormalizedPerformanceForWorkout = (state: IAppState, workoutId: number, score: number) => {
  const histogram = getWorkoutHistogram(state, workoutId);
  if (!histogram) {
    throw new HistogramError('Histogram is empty');
  }
  return getResultNormalizedPerformance(score, histogram);
};
