import { IApiService, getHttpService } from 'web_core_library';

const IterableUrl = 'https://api.iterable.com/api';

export interface IIterableResponsePayload {
  msg: string;
  code:
    | 'Success'
    | 'BadApiKey'
    | 'BadParams'
    | 'BadJsonBody'
    | 'QueueEmailError'
    | 'GenericError'
    | 'InvalidEmailAddressError'
    | 'DatabaseError'
    | 'EmailAlreadyExists'
    | 'Forbidden';
  params?: unknown;
}

interface IAPITokenResponsePayload {
  result: {
    iterableToken: string;
    expiresIn: number;
    tokenType: 'Bearer';
    userId: number;
    apiKey: string;
  };
}

export function getIterableService(httpOverride?: ReturnType<typeof getHttpService>) {
  let ApiService: IApiService;
  const http = httpOverride ? httpOverride : getHttpService(IterableUrl);
  let userId: string;
  let email: string;
  const init = (ApiServiceInstance: IApiService, uId: number, userEmail: string) => {
    ApiService = ApiServiceInstance;
    userId = `${uId}`;
    email = userEmail;
  };
  const getIterableToken = () => {
    return ApiService.action<IAPITokenResponsePayload>('iterable_auth', {
      method: 'post',
      urlParams: {
        userId,
      },
      data: {
        device: 'web',
      },
    });
  };
  const registerPushToken = async (browserToken: string) => {
    if (!userId || !email) {
      throw new Error('IterableService must be initialized first!');
    }
    const tokenResponse = await getIterableToken();
    const { iterableToken, apiKey } = tokenResponse.data.result;
    http.setToken(iterableToken);
    return http.request<IIterableResponsePayload>({
      method: 'POST',
      url: '/users/registerBrowserToken',
      data: {
        email,
        browserToken,
        userId,
      },
      headers: {
        'Api-Key': apiKey,
      },
    });
  };
  return {
    init,
    registerPushToken,
  };
}

const IterableService = getIterableService();

export default IterableService;
