import { Classes } from '@blueprintjs/core';
import { FC } from 'react';
import { Theme } from '../theme';

export interface ICloseButtonProps {
  className?: string;
  onClose?: () => void;
}

const CloseButton: FC<ICloseButtonProps> = ({ className, onClose }) => {
  return (
    <Theme>
      <button className={`${Classes.DIALOG_CLOSE_BUTTON} ${className}`} onClick={onClose}>
        X
      </button>
    </Theme>
  );
};

export default CloseButton;
