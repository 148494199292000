import { Intent } from '@blueprintjs/core';
import { createAction } from '@reduxjs/toolkit';
import React from 'react';
import { INotificationPayload, MessageContent } from './types';

export type TNotificationActionType = 'showNotification';

const showNotification = createAction<INotificationPayload, TNotificationActionType>('showNotification');

export type TNotificationAction = ReturnType<typeof showNotification>;

// helper action builders to provide actions for necessary types of messages
export const showWarning = (message: MessageContent) => showNotification({ intent: Intent.WARNING, message });

export const showError = (message: MessageContent) => showNotification({ intent: Intent.DANGER, message });

export const showSuccess = (message: MessageContent) => showNotification({ intent: Intent.SUCCESS, message });

export const showSuccessMessage = (message: MessageContent, title: MessageContent) => {
  const content = (
    <React.Fragment>
      <h3>{title}</h3>
      <p>{message}</p>
    </React.Fragment>
  );
  return showNotification({ intent: Intent.SUCCESS, message: content });
};

export const showPushMessage = (message: MessageContent, title?: MessageContent, link?: string) => {
  const pushLink = link ? <a href={link}>{link}</a> : null;
  const pushTitle = title ? <h3>{title}</h3> : null;
  const content = (
    <React.Fragment>
      {pushTitle}
      <p>{message}</p>
      {pushLink}
    </React.Fragment>
  );
  return showNotification({ intent: Intent.NONE, message: content });
};

export const NotificationActions = {
  showNotification,
  showError,
  showSuccess,
  showWarning,
  showSuccessMessage,
  showPushMessage,
};
