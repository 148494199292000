import { Action } from 'redux';
import { IHistogramBin } from './types';

export const HISTOGRAM_INIT = 'HISTOGRAM_INIT';
export type HISTOGRAM_INIT_TYPE = typeof HISTOGRAM_INIT;
export interface IInitHistogramAction extends Action<HISTOGRAM_INIT_TYPE> {
  type: HISTOGRAM_INIT_TYPE;
}

export const HISTOGRAM_READY = 'HISTOGRAM_READY';
export type HISTOGRAM_READY_TYPE = typeof HISTOGRAM_READY;
export interface IHistogramReadyAction extends Action<HISTOGRAM_READY_TYPE> {
  type: HISTOGRAM_READY_TYPE;
}

export const HISTOGRAM_LOAD = 'HISTOGRAM_LOAD';
export type HISTOGRAM_LOAD_TYPE = typeof HISTOGRAM_LOAD;
export interface ILoadHistogramAction extends Action<HISTOGRAM_LOAD_TYPE> {
  type: HISTOGRAM_LOAD_TYPE;
  workoutIds: number[];
  groupId: number;
  userId?: number;
}

export const HISTOGRAM_UPDATE = 'HISTOGRAM_UPDATE';
export type HISTOGRAM_UPDATE_TYPE = typeof HISTOGRAM_UPDATE;
export interface IUpdateHistogramAction extends Action<HISTOGRAM_UPDATE_TYPE> {
  type: HISTOGRAM_UPDATE_TYPE;
  workoutId: number;
  histogram: IHistogramBin[];
  userId?: number;
}

export type IHistogramActions =
  | IInitHistogramAction
  | IHistogramReadyAction
  | ILoadHistogramAction
  | IUpdateHistogramAction;
