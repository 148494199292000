import * as React from 'react';
import { Dialog } from '../dialog';
import NavBar, { NavItem } from '../navBar';
import { ILegalLinks } from './types';

export interface ILegalsMenuProps {
  legalLinks: ILegalLinks;
  cookieLinkTitle?: string;
  onToggleCookieSettings: () => void;
}

const DIALOG_CONTENT_HEIGHT = 636;

const LegalsMenu: React.FC<ILegalsMenuProps> = ({ legalLinks, cookieLinkTitle, onToggleCookieSettings }) => {
  const [isDialogOpen, toggleDialog] = React.useState(false);
  const [dialogTitle, setDialogTitle] = React.useState(legalLinks.tou.title);
  const [legalsSrc, setLegalsSrc] = React.useState('');
  const handleDialogClose = () => toggleDialog(false);
  const handleDialogOpen = (title: string, src: string) => (event: React.MouseEvent) => {
    event.preventDefault();
    setDialogTitle(title);
    setLegalsSrc(src);
    toggleDialog(true);
  };

  const onCookiesLinkClick = (event: React.MouseEvent) => {
    event.preventDefault();
    onToggleCookieSettings();
  };

  return (
    <>
      <NavBar className="legals-nav">
        <NavItem className="legals-link" title={legalLinks.tou.title ?? ''}>
          <a href={legalLinks.tou.src} onClick={handleDialogOpen(legalLinks.tou.title, legalLinks.tou.src)}>
            {legalLinks.tou.title}
          </a>
        </NavItem>
        <NavItem className="legals-link" title={legalLinks.policy.title ?? ''}>
          <a href={legalLinks.policy.src} onClick={handleDialogOpen(legalLinks.policy.title, legalLinks.policy.src)}>
            {legalLinks.policy.title}
          </a>
        </NavItem>
        {cookieLinkTitle && (
          <NavItem className="legals-link" title={cookieLinkTitle ?? ''}>
            <a href="/" onClick={onCookiesLinkClick}>
              {cookieLinkTitle}
            </a>
          </NavItem>
        )}
        <NavItem className="legals-link" title={legalLinks.imprint.title ?? ''}>
          <a href={legalLinks.imprint.src} onClick={handleDialogOpen(legalLinks.imprint.title, legalLinks.imprint.src)}>
            {legalLinks.imprint.title}
          </a>
        </NavItem>
      </NavBar>
      <Dialog
        portalClassName="legals-portal"
        className="legals-dialog"
        onClose={handleDialogClose}
        title={dialogTitle}
        isOpen={isDialogOpen}
        bodyClassName="legals-dialog-body">
        <iframe className="legals-frame" title={dialogTitle ?? ''} height={DIALOG_CONTENT_HEIGHT} src={legalsSrc} />
      </Dialog>
    </>
  );
};

export default LegalsMenu;
