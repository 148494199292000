import * as ActionTypes from './actionTypes';
import { IUrlParams } from './attributionService';
import { EBannerMode, ICookieConsent, IEventActionPayload, IMessage } from './types';

/**
 * initialize firebase with defined configuration parameter
 */
export const firebaseInitAction: (
  apiKey: string,
  projectId: string,
  appId: string,
  messagingSenderId: string,
  measurementId: string,
  searchParams: URLSearchParams
) => ActionTypes.IFirebaseInitAction = (apiKey, projectId, appId, messagingSenderId, measurementId, searchParams) => ({
  type: ActionTypes.FIREBASE_INIT,
  apiKey,
  projectId,
  appId,
  messagingSenderId,
  measurementId,
  searchParams,
});

// send a subscribe event to Taboola
export const firebaseInitializedAction: () => ActionTypes.IFirebaseInitializedAction = () => ({
  type: ActionTypes.FIREBASE_INITIALIZED,
});

// initalize necessary apps from firebase sdk
export const firebaseStartAction: (options: {
  analytics?: boolean;
  config?: boolean;
  messaging?: boolean;
}) => ActionTypes.IFirebaseStartAction = (options) => ({
  type: ActionTypes.FIREBASE_START,
  options,
});

export const firebaseReadyAction: (
  analytics: boolean,
  config: boolean,
  messaging: boolean
) => ActionTypes.IFirebaseReadyAction = (analytics, config, messaging) => ({
  type: ActionTypes.FIREBASE_READY,
  analytics,
  config,
  messaging,
});

// send an user action / response event
export const analyticsEventAction: (analyticsEvent: IEventActionPayload) => ActionTypes.IAnalyticsEventAction = (
  analyticsEvent
) => ({
  type: ActionTypes.ANALYTICS_EVENT,
  analyticsEvent,
});

// update firebase consent
export const updateFirebaseConsentAction: (
  analytics: boolean,
  userData: boolean
) => ActionTypes.IUpdateFirebaseConsentAction = (analytics, userData) => ({
  type: ActionTypes.FIREBASE_UPDATE_CONSENT,
  analytics,
  userData,
});

// set userId when available
export const setUserAction: (userId: number, email?: string) => ActionTypes.ISetUserAction = (userId, email) => ({
  type: ActionTypes.SET_USER,
  userId,
  email,
});

// init taboola service with click id
export const initTaboolaAction: (taboolaClickId?: string) => ActionTypes.IInitTaboolaAction = (taboolaClickId) => ({
  type: ActionTypes.TABOOLA_INIT,
  taboolaClickId,
});

// send a subscribe event to Taboola
export const taboolaSubscribeEventAction: () => ActionTypes.ITaboolaSubscribeEventAction = () => ({
  type: ActionTypes.TABOOLA_SUBSCRIBE_EVENT,
});

// send a validation event to Taboola
export const taboolaValidationEventAction: () => ActionTypes.ITaboolaValidationEventAction = () => ({
  type: ActionTypes.TABOOLA_VALIDATION_EVENT,
});

// init outbrain service with click id
export const initOutbrainAction: (outbrainClickId?: string) => ActionTypes.IInitOutbrainAction = (outbrainClickId) => ({
  type: ActionTypes.OUTBRAIN_INIT,
  outbrainClickId,
});

// send a validation event to outbrain
export const outbrainSubscribeEventAction: () => ActionTypes.IOutbrainSubscribeEventAction = () => ({
  type: ActionTypes.OUTBRAIN_SUBSCRIBE_EVENT,
});

// send a validation event to outbrain
export const outbrainValidationEventAction: () => ActionTypes.IOutbrainValidationEventAction = () => ({
  type: ActionTypes.OUTBRAIN_VALIDATION_EVENT,
});

// set user property
export const setUserPropertyAction: (
  name: string,
  value: string | number | boolean | undefined
) => ActionTypes.ISetPropertyAction = (name, value) => ({
  type: ActionTypes.SET_PROPERTY,
  name,
  value,
});

// request push permission
export const requestPushPermissionAction: (vapidKey: string) => ActionTypes.IRequestPermissionAction = (vapidKey) => ({
  type: ActionTypes.REQUEST_PUSH_PERMISSION,
  vapidKey,
});

// push permission result
export const pushPermissionResultAction: (
  permission: boolean,
  reason?: string,
  unsupported?: boolean
) => ActionTypes.IPermissionResultAction = (permission, reason, unsupported) => ({
  type: ActionTypes.PUSH_PERMISSION_RESULT,
  permission,
  reason,
  unsupported,
});

// messaging must be enabled and we need to get token for push
export const enableMessagingAction: (vapidKey: string) => ActionTypes.IFirebaseEnableMessagingAction = (vapidKey) => ({
  type: ActionTypes.FIREBASE_ENABLE_MESSAGING,
  vapidKey,
});

// messaging is enabled and we are ready to receive messages
export const messagingEnabledAction: () => ActionTypes.IFirebaseMessagingEnabledAction = () => ({
  type: ActionTypes.FIREBASE_MESSAGING_ENABLED,
});

// messaging is enabled and we need to get token for push
export const messageReceivedAction: (payload: IMessage) => ActionTypes.IMessageReceivedAction = (payload) => ({
  type: ActionTypes.FIREBASE_MESSAGE_RECEIVED,
  payload,
});

// message was shown to user and can be removed from state
export const messageShownAction: (message: IMessage) => ActionTypes.IMessageShownAction = (message) => ({
  type: ActionTypes.FIREBASE_MESSAGE_SHOWN,
  message,
});

// generic tracking event for unexpected crashes
export const firebaseErrorReportAction: (
  page: string,
  feature: string,
  message: string
) => ActionTypes.IAnalyticsEventAction = (page, feature, message) => {
  const event: IEventActionPayload = {
    eventName: 'Error_frontend',
    parameters: [
      {
        key: 'Page',
        value: page,
      },
      {
        key: 'Feature',
        value: feature,
      },
      {
        key: 'Message',
        value: message,
      },
    ],
  };
  return analyticsEventAction(event);
};

// GDPR Actions
export const updateUtmTrackingAction: (utm: IUrlParams) => ActionTypes.IUpdateUtmTrackingAction = (utm) => ({
  type: ActionTypes.GDPR_UPDATE_UTM,
  utm,
});

export const updateBannerModeAction: (mode: EBannerMode) => ActionTypes.IUpdateBannerModeAction = (mode) => ({
  type: ActionTypes.BANNER_MODE_UPDATE,
  mode,
});

export const updateConsentAction: (consent: ICookieConsent) => ActionTypes.IUpdateConsentAction = (consent) => ({
  type: ActionTypes.GDPR_UPDATE_CONSENT,
  consent,
});

export const acceptAllAction = () => {
  const consent: ICookieConsent = {
    firebase: true,
    userData: true,
  };
  return saveConsentAction(consent);
};

export const declineAllAction = () => {
  const consent: ICookieConsent = {
    firebase: false,
    userData: false,
  };
  return saveConsentAction(consent);
};

export const saveConsentAction: (consent: ICookieConsent) => ActionTypes.ISaveUserConsentAction = (consent) => ({
  type: ActionTypes.GDPR_SAVE_USER_CONSENT,
  consent,
});

export const toggleSettingsAction: () => ActionTypes.IToggleSettingsAction = () => ({
  type: ActionTypes.GDPR_SETTINGS_TOGGLE,
});

export const initGdprExportAction: () => ActionTypes.IInitGdprExportAction = () => ({
  type: ActionTypes.GDPR_INIT_EXPORT,
});

export const requestExportAction: () => ActionTypes.IRequestExportAction = () => ({
  type: ActionTypes.GDPR_EXPORT_REQUEST,
});

// Further GDPR actions
export const requestExportSuccessAction: () => ActionTypes.IRequestExportSuccessAction = () => ({
  type: ActionTypes.GDPR_EXPORT_REQUEST_SUCCESS,
});

export const requestExportFailureAction: () => ActionTypes.IRequestExportFailureAction = () => ({
  type: ActionTypes.GDPR_EXPORT_REQUEST_FAILURE,
});
