import { Classes } from '@blueprintjs/core';
import { FC } from 'react';
import { Theme } from '../theme';

export interface IDialogHeaderProps {
  className?: string;
}

const DialogHeader: FC<React.PropsWithChildren<IDialogHeaderProps>> = ({ className, children }) => {
  return (
    <Theme>
      <div className={`${Classes.DIALOG_HEADER} ${className}`}>{children}</div>
    </Theme>
  );
};

export default DialogHeader;
