import { HtmlTagRule } from './types';

// Allowed HTML tags for converting text to React elements
export const ALLOWED_HTML_TAGS: HtmlTagRule[] = [
  {
    tag: 'a',
    allowed_attributes: ['href', 'target', 'rel'],
  },
  { tag: 'b' },
  { tag: 'i' },
  { tag: 'p' },
  { tag: 'strong' },
  { tag: 'em' },
  { tag: 'pre' },
  {
    tag: 'span',
    allowed_attributes: ['class'],
  },
  { tag: 'div' },
  { tag: 'ul' },
  { tag: 'ol' },
  { tag: 'li' },
];
