import * as ActionTypes from './actionTypes';
import { IWorkoutHistogram } from './types';

export interface IHistogramState {
  loading: boolean;
  histogram: IWorkoutHistogram;
  users: {
    [userId: number]: IWorkoutHistogram;
  };
}

export const initialState: IHistogramState = {
  loading: true,
  histogram: {},
  users: {},
};

export const statePropName = 'histogram';

export default function histogramReducer(
  state: IHistogramState = initialState,
  action: ActionTypes.IHistogramActions
): IHistogramState {
  switch (action.type) {
    case ActionTypes.HISTOGRAM_READY: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionTypes.HISTOGRAM_UPDATE: {
      const { workoutId, histogram, userId } = action;
      if (!userId) {
        const updatedHistogram = {
          ...state.histogram,
          [workoutId]: histogram,
        };
        return {
          ...state,
          histogram: updatedHistogram,
        };
      }
      const userHistograms = state.users[userId] || {};
      const updatedHistogram = {
        ...userHistograms,
        [workoutId]: histogram,
      };
      const updatedUsers = {
        ...state.users,
        [userId]: updatedHistogram,
      };
      return {
        ...state,
        users: updatedUsers,
      };
    }
  }
  return state;
}
