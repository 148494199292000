import { Classes, Dialog } from '@blueprintjs/core';
import React, { FC, ReactNode } from 'react';

import { Theme } from '../theme';
import CloseButton from './CloseButton';
import './Dialog.scss';
import DialogFooter from './DialogFooter';
import DialogHeader from './DialogHeader';

export interface IDialogProps {
  isOpen: boolean;
  autoFocus?: boolean;
  bodyClassName?: string;
  canEscapeKeyClose?: boolean;
  canOutsideClickClose?: boolean;
  className?: string;
  closeBtnClassName?: string;
  footerClassName?: string;
  footerActionsClassName?: string;
  footerContent?: ReactNode;
  headerClassName?: string;
  headerContent?: ReactNode;
  title?: ReactNode;
  lazy?: boolean;
  onClose?: () => void;
  showCloseButton?: boolean;
  usePortal?: boolean;
  portalClassName?: string;
}

const DialogComponent: FC<React.PropsWithChildren<IDialogProps>> = ({
  autoFocus = true,
  bodyClassName = '',
  canOutsideClickClose = true,
  canEscapeKeyClose = true,
  children,
  className = '',
  closeBtnClassName = '',
  footerClassName,
  footerContent,
  headerClassName,
  footerActionsClassName,
  headerContent,
  isOpen,
  title,
  lazy = true,
  onClose,
  showCloseButton,
  usePortal = true,
  portalClassName = 'dialog-portal',
}) => (
  <Dialog
    portalClassName={portalClassName}
    lazy={lazy}
    usePortal={usePortal}
    autoFocus={autoFocus}
    isOpen={isOpen}
    canOutsideClickClose={canOutsideClickClose}
    canEscapeKeyClose={canEscapeKeyClose}
    onClose={onClose}
    title={title}
    className={`${Classes.DIALOG} ${className}`}>
    {headerContent && <DialogHeader className={headerClassName}>{headerContent}</DialogHeader>}
    {showCloseButton && <CloseButton className={closeBtnClassName} onClose={onClose} />}
    <Theme>
      <div className={`${Classes.DIALOG_BODY} ${bodyClassName}`}>{children}</div>
    </Theme>
    {footerContent && (
      <DialogFooter className={footerClassName} actionsClassName={footerActionsClassName}>
        {footerContent}
      </DialogFooter>
    )}
  </Dialog>
);

export default DialogComponent;
