import * as React from 'react';
import './Section.scss';
import SectionSubtitle from './SectionSubtitle';
import SectionTitle from './SectionTitle';

export enum ESectionColor {
  blue = 'section__blue',
  orange = 'section__orange',
}

export enum ESectionType {
  basic = 'section__basic',
  fullscreen = 'section__fullscreen',
}

export enum ESectionAlign {
  left = 'section__float-left',
  center = 'section__float-center',
  right = 'section__float-right',
}

export interface ISectionProps {
  type?: ESectionType;
  color?: ESectionColor;
  title?: string;
  subtitle?: string;
  className?: string;
  id?: string;
  // FIXME: this parameter is deprecated and should not be used (BW-7362)
  align?: ESectionAlign;
  // TODO: refactoring needed for paddings (BW-7362)
  noPadding?: boolean;
}

export const Section: React.FC<React.PropsWithChildren<ISectionProps>> = ({
  children,
  title,
  subtitle,
  type = ESectionType.basic,
  color,
  className = '',
  id,
  align,
  noPadding = false,
}) => (
  <div
    id={id}
    className={`section ${type} ${color ? color : ''} ${align ? align : ''} ${className}${
      noPadding ? ' no-padding' : ''
    }`}>
    <div className="container">
      {!!title && title.length > 0 && <SectionTitle>{title}</SectionTitle>}
      {!!subtitle && subtitle.length > 0 && <SectionSubtitle>{subtitle}</SectionSubtitle>}
      {children}
    </div>
  </div>
);

export default Section;
