import { all, call, delay, put, race, select, take, takeEvery, takeLatest, takeLeading } from 'redux-saga/effects';
import { ApiService } from 'web_core_library';
import * as ActionTypes from './actionTypes';
import * as Actions from './actions';
import * as AnalyticsApp from './analytics';
import * as FirebaseApp from './app';
import AttributionService, { IUrlParams } from './attributionService';
import * as GdprService from './gdprService';
import IterableService from './iterableService';
import * as MessaginApp from './messaging';
import outbrainService from './outbrainService';
import * as RemoteConfigApp from './remoteConfig';
import * as Selectors from './selectors';
import taboolaService from './taboolaService';
import { EBannerMode, ICookieConsent } from './types';

export function* initializeFirebase({
  apiKey,
  appId,
  measurementId,
  messagingSenderId,
  projectId,
  searchParams,
}: ActionTypes.IFirebaseInitAction) {
  yield call(FirebaseApp.init, apiKey, projectId, appId, messagingSenderId, measurementId);
  // Initializing GDPR
  yield call(detectUTMTracking, searchParams);
  yield call(detectBannerMode);
  const taboolaId: string | undefined = yield select(Selectors.getTaboolaId);
  const outbrainId: string | undefined = yield select(Selectors.getOutbrainId);
  yield put(Actions.initTaboolaAction(taboolaId));
  yield put(Actions.initOutbrainAction(outbrainId));
  const consent: ICookieConsent | undefined = yield call(GdprService.getConsent);
  if (consent) {
    yield put(Actions.updateConsentAction(consent));
  }
  yield put(Actions.firebaseInitializedAction());
}

export function* startFirebaseApps({ options }: ActionTypes.IFirebaseStartAction) {
  // wait for firebase core app to be initalized
  const isInitialized: boolean = yield select(Selectors.isInitialized);
  if (!isInitialized) {
    yield take(ActionTypes.FIREBASE_INITIALIZED);
  }
  const sessionId: string = yield select(Selectors.getSessionId);
  const { analytics, config, messaging } = options;
  if (analytics) {
    yield call(AnalyticsApp.initAnalytics, sessionId);
  }
  if (config) {
    yield call(RemoteConfigApp.init);
    yield call(RemoteConfigApp.fetchConfig);
  }
  if (messaging) {
    yield call(MessaginApp.init);
  }
  yield put(Actions.firebaseReadyAction(!!analytics, !!config, !!messaging));
}

export function* waitToBeReady() {
  const ready: boolean = yield select(Selectors.isReady);
  if (!ready) {
    yield take(ActionTypes.FIREBASE_READY);
  }
}

export function* configureTracking({ analytics, userData }: ActionTypes.IUpdateFirebaseConsentAction) {
  yield call(waitToBeReady);
  yield call(AnalyticsApp.setCookieConsentProperty, analytics);
  yield call(AnalyticsApp.updateConsent, analytics, userData);
}

// fire custom event
export function* sendAnalyticsEvent({ analyticsEvent }: ActionTypes.IAnalyticsEventAction) {
  yield call(waitToBeReady);
  yield call(AnalyticsApp.analyticsEvent, analyticsEvent);
}

export function* setUserSaga({ userId, email }: ActionTypes.ISetUserAction) {
  yield call(waitToBeReady);
  // set user id in Firebase
  yield call(AnalyticsApp.setAnalyticsUserId, userId);
  const messagingEnabled: boolean = yield select(Selectors.isMessagingActive);
  if (!messagingEnabled) {
    // skip iterable if messagin was not turned on
    return;
  }
  if (!email) {
    throw new Error('Email of the user must be specified for Messaging app to work!');
  }
  yield call(IterableService.init, ApiService, userId, email);
}

export function* initTaboolaSaga({ taboolaClickId }: ActionTypes.IInitTaboolaAction) {
  // configure taboola service with api service click id
  yield call(taboolaService.init, ApiService, taboolaClickId);
}

export function* sendTaboolaSubscribeSaga() {
  yield call(taboolaService.trackSubscribe);
}

export function* sendTaboolaValidationSaga() {
  try {
    yield call(taboolaService.trackValidation);
  } catch (error) {
    console.log(error);
  }
}

export function* initOutbrainServiceSaga({ outbrainClickId }: ActionTypes.IInitOutbrainAction) {
  // configure outbrain service
  yield call(outbrainService.init, ApiService, outbrainClickId);
}

export function* sendOutbrainSubscribeEventSaga() {
  yield call(outbrainService.trackSubscribe);
}

export function* sendOutbrainValidationSaga() {
  try {
    yield call(outbrainService.trackValidation);
  } catch (error) {
    console.log(error);
  }
}

// set user property
export function* setUserProperty(action: ActionTypes.ISetPropertyAction) {
  yield call(waitToBeReady);
  const { name, value } = action;
  yield call(AnalyticsApp.setProperty, name, value);
}

export function* requestPushPermission({ vapidKey }: ActionTypes.IRequestPermissionAction) {
  try {
    const { token }: { token?: string; timeout?: boolean } = yield race({
      token: call(MessaginApp.getPushToken, vapidKey),
      // we wait for some delay because Edge browser does not reject promise when permission is blocked
      timeout: delay(15000),
    });
    if (!token) {
      // if browser does not support PUSH getPushToken will return undefined
      yield put(Actions.pushPermissionResultAction(false, 'Push API not supported!', true));
      return;
    }
    // token received
    yield call(savePushTokenToIterable, token);
  } catch (error) {
    try {
      // try to get token, in case request failed due to firebase bug
      const token: string | undefined = yield call(MessaginApp.getPushToken, vapidKey);
      if (!token) {
        // if browser does not support PUSH getPushToken will return undefined
        yield put(Actions.pushPermissionResultAction(false, 'Push API not supported!', true));
        return;
      }
      // token received
      yield call(savePushTokenToIterable, token);
      return;
    } catch (ignoredError) {
      // this error we ignore
    }
    // token does not exist - permission was blocked
    yield put(Actions.pushPermissionResultAction(false, 'Permission not granted!'));
  }
}

export function* savePushTokenToIterable(token: string) {
  try {
    yield call(IterableService.registerPushToken, token);
  } catch (error) {
    yield put(Actions.pushPermissionResultAction(false, 'Failed to register token!'));
    return;
  }
  yield put(Actions.pushPermissionResultAction(true));
}

export function* enableMessagingSaga({ vapidKey }: ActionTypes.IFirebaseEnableMessagingAction) {
  yield call(waitToBeReady);
  yield put(Actions.requestPushPermissionAction(vapidKey));
  const result: ActionTypes.IPermissionResultAction = yield take(ActionTypes.PUSH_PERMISSION_RESULT);
  if (!result.permission) {
    yield call(console.warn, ['Failed to set up messaging!', result.reason]);
    return;
  }
  yield put(Actions.messagingEnabledAction());
}

export function* detectUTMTracking(searchParams: URLSearchParams) {
  // restore parameters set before
  yield call(AttributionService.restoreParams);
  // parse current url and update parameters in storage
  yield call(AttributionService.parseParamsFromUrl, searchParams);
  const utmParams: IUrlParams = yield call(AttributionService.getParams);
  // update data in state
  yield put(Actions.updateUtmTrackingAction(utmParams));
}

export function* detectBannerMode() {
  // detectUTMTracking must be run before this function
  const cks: string | undefined = yield select(Selectors.getCks);
  if (typeof cks === 'undefined') {
    // do nothing if parameter not set
    return;
  }
  const mode: EBannerMode = Number(cks);
  yield put(Actions.updateBannerModeAction(mode));
}

export function* useConsentSaga({ consent }: ActionTypes.IUpdateConsentAction) {
  // start/init features depending on users consent
  const { firebase, userData } = consent;
  yield put(Actions.updateFirebaseConsentAction(firebase, userData));
}

export function* saveUserConsentSaga({ consent }: ActionTypes.ISaveUserConsentAction) {
  yield call(GdprService.saveConsent, consent);
  yield put(Actions.updateConsentAction(consent));
}

export function* requestExportSaga() {
  try {
    yield call(GdprService.GDPRHttpService.requestGDPRExport);
    yield put(Actions.requestExportSuccessAction());
  } catch (error) {
    yield put(Actions.requestExportFailureAction());
  }
}

export function* initGdprExportSaga() {
  yield call(GdprService.GDPRHttpService.init, ApiService);
}

// base watcher for Firebase actions
export default function* watchFirebase() {
  yield all([
    takeLatest(ActionTypes.FIREBASE_INIT, initializeFirebase),
    takeLatest(ActionTypes.FIREBASE_START, startFirebaseApps),
    takeEvery(ActionTypes.FIREBASE_UPDATE_CONSENT, configureTracking),
    takeEvery(ActionTypes.ANALYTICS_EVENT, sendAnalyticsEvent),
    takeLatest(ActionTypes.SET_USER, setUserSaga),
    takeEvery(ActionTypes.TABOOLA_INIT, initTaboolaSaga),
    takeEvery(ActionTypes.TABOOLA_SUBSCRIBE_EVENT, sendTaboolaSubscribeSaga),
    takeEvery(ActionTypes.TABOOLA_VALIDATION_EVENT, sendTaboolaValidationSaga),
    takeEvery(ActionTypes.OUTBRAIN_INIT, initOutbrainServiceSaga),
    takeEvery(ActionTypes.OUTBRAIN_SUBSCRIBE_EVENT, sendOutbrainSubscribeEventSaga),
    takeEvery(ActionTypes.OUTBRAIN_VALIDATION_EVENT, sendOutbrainValidationSaga),
    takeEvery(ActionTypes.SET_PROPERTY, setUserProperty),
    takeLeading(ActionTypes.REQUEST_PUSH_PERMISSION, requestPushPermission),
    takeEvery(ActionTypes.FIREBASE_ENABLE_MESSAGING, enableMessagingSaga),
    takeEvery(ActionTypes.GDPR_UPDATE_CONSENT, useConsentSaga),
    takeEvery(ActionTypes.GDPR_SAVE_USER_CONSENT, saveUserConsentSaga),
    takeLeading(ActionTypes.GDPR_EXPORT_REQUEST, requestExportSaga),
    takeEvery(ActionTypes.GDPR_INIT_EXPORT, initGdprExportSaga),
  ]);
}
