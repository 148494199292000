import * as React from 'react';

export interface IThemeProps {
  theme?: string;
}

export enum EThemeName {
  Edison = 'theme-edison',
}

const Theme: React.FC<React.PropsWithChildren<IThemeProps>> = ({ theme = EThemeName.Edison, children }) => (
  <div className={theme}>{children}</div>
);

export default Theme;
