import { ELanguage } from '@web_opxp/features';
import { EDomain, EExerciseCategory, IMapDomainIds } from './types';

export const BUILD_ID_FALLBACK = 'local-build';

export const TRAINING_ID = 1;

export const ASSESSMENT_SESSION_ID = 0;

export const ASSESSMENT_DOMAIN_ID = 0;

export const SKELETON_CLASSNAME = 'bp5-skeleton';
// this value will never be shown and is used to put some content to not let html tags collapse
export const SKELETON_DUMMYTEXT = 'Loading...';

export const WELCOME_ANCHOR = 'welcome-page';

export const NO_ALERT_TYPE = 'noAlert';

export const DEFAULT_CATEGORY_ID = EExerciseCategory.Medium;

export const TRAINING_DOMAINS = [EDomain.Speed, EDomain.Reasoning, EDomain.Attention, EDomain.Memory];

export const MapDomainIds: IMapDomainIds = {
  1: 'Speed',
  2: 'Reasoning',
  3: 'Attention',
  4: 'Memory',
};

// Stat constants
export const ONBOARDING_VERSION = '4-0-0';
export const WORKOUT_PROGRESS_MIGRATION = '9-1-9';
export const TRAINING_REMINDER = '9-6-1';
export const TRAINING_LED = '9-7-0';
export const GAMEPLAY_SOUND = '4-4-100';
export const GAMEPLAY_BACKGROUND = '4-4-101';
export const GAMEPLAY_FULLSCREEN = '4-4-102';
export const MESSAGE_EXPERIMENT = '4-7-7';

// stat definition parts for category mapping for exercises
export const CATEGORY_STAT_TYPE = 0;
export const CATEGORY_COMPONENT_ID = 6;

export const ONBOARDING_AVAILABLE_LANGUAGES = [
  ELanguage.German,
  ELanguage.English,
  ELanguage.French,
  ELanguage.Russian,
];
