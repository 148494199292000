import * as ActionTypes from './actionTypes';
import { IHistogramBin } from './types';

export const initHistogramAction: () => ActionTypes.IInitHistogramAction = () => ({
  type: ActionTypes.HISTOGRAM_INIT,
});

export const histogramReadyAction: () => ActionTypes.IHistogramReadyAction = () => ({
  type: ActionTypes.HISTOGRAM_READY,
});

export const loadHistogramAction: (
  workoutIds: number[],
  groupId: number | null,
  userId?: number
) => ActionTypes.ILoadHistogramAction = (workoutIds, groupId, userId) => ({
  type: ActionTypes.HISTOGRAM_LOAD,
  workoutIds,
  // BW-7839 fallback to zero when group id not set, groupId=0 - everyone
  groupId: groupId === null ? 0 : groupId,
  userId,
});

export const updateHistogramAction: (
  workoutId: number,
  histogram: IHistogramBin[],
  userId?: number
) => ActionTypes.IUpdateHistogramAction = (workoutId, histogram, userId) => ({
  type: ActionTypes.HISTOGRAM_UPDATE,
  workoutId,
  histogram,
  userId,
});
